import Blockly from 'blockly';
import Robovac from "./robovac/main";

window.__VUE_OPTIONS_API__ = true;
window.__VUE_PROD_DEVTOOLS__ = false;

let blocklyArea = document.getElementById('blocklyArea');
let blocklyDiv = document.getElementById('blocklyDiv');
let workspace = Blockly.inject(blocklyDiv, {
    toolbox: {
        contents: []
    },
    renderer: 'zelos',
    zoom: {
        controls: true,
        startScale: 0.9,
        scaleSpeed: 1/0.9
    }
});

window.algolabRunning = false;
document.getElementById('stopButton').onclick = function () {
    window.algolabRunning = false;
}

Robovac.initialize(workspace);

// https://developers.google.com/blockly/guides/configure/web/resizable
let onresize = function() {
    // Compute the absolute coordinates and dimensions of blocklyArea
    let element = blocklyArea;
    let x = 0;
    let y = 0;
    do {
        x += element.offsetLeft;
        y += element.offsetTop;
        element = element.offsetParent;
    } while (element);
    // Position blocklyDiv over blocklyArea
    blocklyDiv.style.left = x + 'px';
    blocklyDiv.style.top = y + 'px';
    blocklyDiv.style.width = blocklyArea.offsetWidth + 'px';
    blocklyDiv.style.height = blocklyArea.offsetHeight + 'px';
    Blockly.svgResize(workspace);
};
window.addEventListener('resize', onresize, false);
onresize();
