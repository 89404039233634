import {Situation, Level} from "./model";

const N = 'NORTH';
const E = 'EAST';
const S = 'SOUTH';
const W = 'WEST';

let levels = [
    new Level([new Situation([
        [0, 0, 0, 0, 0],
        [0, E, 1, 1, 0],
        [0, 1, 1, 1, 0],
        [0, 0, 0, 0, 0]
    ])], Infinity, [
        'robovac_move',
        'robovac_rotate'
    ]),
    new Level([new Situation([
        [0, 0, 0, 0, 0],
        [0, 1, S, 1, 0],
        [0, 1, 1, 1, 0],
        [0, 0, 0, 0, 0]
    ])], Infinity, [
        'robovac_move',
        'robovac_rotate'
    ]),
    new Level([new Situation([
        [0, 0, 0, 0, 0],
        [0, E, 1, 1, 0],
        [0, 0, 0, 0, 0]
    ]), new Situation([
        [0, 0, 0, 0, 0, 0, 0],
        [0, E, 1, 1, 1, 1, 0],
        [0, 0, 0, 0, 0, 0, 0]
    ]), new Situation([
        [0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, E, 1, 1, 1, 1, 1, 1, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0]
    ])], Infinity, [
        'robovac_move',
        'robovac_rotate',
        'robovac_repeat_until_clean'
    ]),
    new Level([new Situation([
        [0, 0, 0, 0, 0],
        [0, 1, 1, 1, 0],
        [0, 1, 0, 0, 0],
        [0, N, 0, 0, 0],
        [0, 0, 0, 0, 0]
    ]), new Situation([
        [0, 0, 0, 0, 0, 0, 0],
        [0, 1, 1, 1, 1, 1, 0],
        [0, 1, 0, 0, 0, 0, 0],
        [0, N, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0]
    ]), new Situation([
        [0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 1, 1, 1, 1, 1, 1, 1, 0],
        [0, 1, 0, 0, 0, 0, 0, 0, 0],
        [0, N, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0]
    ])], Infinity, [
        'robovac_move',
        'robovac_rotate',
        'robovac_repeat_until_clean'
    ]),
    new Level([new Situation([
        [0, 0, 0, 0, 0],
        [0, E, 1, 1, 0],
        [0, 0, 0, 1, 0],
        [0, 0, 0, 0, 0]
    ]), new Situation([
        [0, 0, 0, 0, 0, 0, 0],
        [0, E, 1, 1, 1, 1, 0],
        [0, 0, 0, 0, 0, 1, 0],
        [0, 0, 0, 0, 0, 1, 0],
        [0, 0, 0, 0, 0, 1, 0],
        [0, 0, 0, 0, 0, 0, 0]
    ]), new Situation([
        [0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, E, 1, 1, 1, 1, 1, 1, 0],
        [0, 0, 0, 0, 0, 0, 0, 1, 0],
        [0, 0, 0, 0, 0, 0, 0, 1, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0]
    ])], Infinity, [
        'robovac_move',
        'robovac_rotate',
        'robovac_repeat_until_clean',
        'robovac_repeat'
    ]),
    new Level([new Situation([
        [0, 0, 0, 0, 0],
        [0, E, 1, 1, 0],
        [0, 1, 1, 1, 0],
        [0, 0, 0, 0, 0]
    ]), new Situation([
        [0, 0, 0, 0, 0, 0, 0],
        [0, E, 1, 1, 1, 1, 0],
        [0, 1, 1, 1, 1, 1, 0],
        [0, 0, 0, 0, 0, 0, 0]
    ]), new Situation([
        [0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, E, 1, 1, 1, 1, 1, 1, 0],
        [0, 1, 1, 1, 1, 1, 1, 1, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0]
    ])], Infinity, [
        'robovac_move',
        'robovac_rotate',
        'robovac_repeat_until_clean',
        'robovac_repeat'
    ]),
    new Level([new Situation([
        [0, 0, 0, 0, 0, 0],
        [0, E, 1, 1, 1, 0],
        [0, 1, 1, 1, 1, 0],
        [0, 0, 0, 1, 1, 0],
        [0, 0, 0, 1, 1, 0],
        [0, 0, 0, 0, 0, 0]
    ]), new Situation([
        [0, 0, 0, 0, 0, 0, 0, 0],
        [0, E, 1, 1, 1, 1, 1, 0],
        [0, 1, 1, 1, 1, 1, 1, 0],
        [0, 0, 0, 0, 0, 1, 1, 0],
        [0, 0, 0, 0, 0, 1, 1, 0],
        [0, 0, 0, 0, 0, 1, 1, 0],
        [0, 0, 0, 0, 0, 0, 0, 0]
    ]), new Situation([
        [0, 0, 0, 0, 0, 0, 0, 0],
        [0, E, 1, 1, 1, 1, 1, 0],
        [0, 1, 1, 1, 1, 1, 1, 0],
        [0, 0, 0, 0, 0, 0, 0, 0]
    ])], Infinity, [
        'robovac_move',
        'robovac_rotate',
        'robovac_repeat_until_clean',
        'robovac_repeat'
    ]),
    new Level([new Situation([
        [0, 0, 0, 0, 0, 0, 0],
        [0, 2, 2, 2, 2, 2, 0],
        [0, E, 1, 1, 1, 2, 0],
        [0, 2, 1, 1, 1, 2, 0],
        [0, 2, 2, 2, 2, 2, 0],
        [0, 0, 0, 0, 0, 0, 0]
    ]), new Situation([
        [0, 0, 0, 0, 0, 0, 0],
        [0, 2, 2, 2, 2, 2, 0],
        [0, E, 1, 1, 1, 2, 0],
        [0, 2, 1, 1, 1, 2, 0],
        [0, 2, 1, 1, 1, 2, 0],
        [0, 2, 1, 1, 1, 2, 0],
        [0, 2, 2, 2, 2, 2, 0],
        [0, 0, 0, 0, 0, 0, 0]
    ]), new Situation([
        [0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 2, 2, 2, 2, 2, 2, 2, 0],
        [0, E, 1, 1, 1, 1, 1, 2, 0],
        [0, 2, 1, 1, 1, 1, 1, 2, 0],
        [0, 2, 1, 1, 1, 1, 1, 2, 0],
        [0, 2, 1, 1, 1, 1, 1, 2, 0],
        [0, 2, 2, 2, 2, 2, 2, 2, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0]
    ])], Infinity, [
        'robovac_move',
        'robovac_rotate',
        'robovac_repeat_until_clean',
        'robovac_repeat'
    ]),
    new Level([new Situation([
        [0, 0, 0, 0, 0],
        [0, E, 1, 1, 0],
        [0, 0, 0, 0, 0]
    ]), new Situation([
        [0, 0, 0, 0, 0],
        [0, 1, 1, 1, 0],
        [0, E, 1, 1, 0],
        [0, 0, 0, 0, 0]
    ]), new Situation([
        [0, 0, 0, 0, 0],
        [0, 1, 1, W, 0],
        [0, 1, 1, 1, 0],
        [0, 0, 0, 0, 0]
    ])], Infinity, [
        'robovac_move',
        'robovac_rotate',
        'robovac_if'
    ]),
    new Level([new Situation([
        [0, 0, 0, 0, 0],
        [0, 0, 0, 1, 0],
        [0, 0, 0, 1, 0],
        [0, E, 1, 1, 0],
        [0, 0, 0, 0, 0]
    ]), new Situation([
        [0, 0, 0, 0, 0],
        [0, S, 0, 0, 0],
        [0, 1, 0, 0, 0],
        [0, 1, 1, 1, 0],
        [0, 0, 0, 0, 0]
    ]), new Situation([
        [0, 0, 0, 0, 0],
        [0, E, 1, 1, 0],
        [0, 0, 0, 1, 0],
        [0, 0, 0, 1, 0],
        [0, 0, 0, 0, 0]
    ])], Infinity, [
        'robovac_move',
        'robovac_rotate',
        'robovac_if_else'
    ]),
    new Level([new Situation([
        [0, 0, 0, 0, 0],
        [0, 0, 0, 1, 0],
        [0, 0, 0, 1, 0],
        [0, E, 1, 1, 0],
        [0, 0, 0, 0, 0]
    ]), new Situation([
        [0, 0, 0, 0, 0],
        [0, E, 1, 1, 0],
        [0, 0, 0, 1, 0],
        [0, 0, 0, 1, 0],
        [0, 0, 0, 0, 0]
    ]), new Situation([
        [0, 0, 0, 0, 0, 0, 0],
        [0, E, 1, 1, 1, 1, 0],
        [0, 0, 0, 0, 0, 0, 0]
    ])], Infinity, [
        'robovac_move',
        'robovac_rotate',
        'robovac_if',
        'robovac_if_else'
    ]),
    new Level([new Situation([
        [0, 0, 0, 0, 0],
        [0, 1, 1, 1, 0],
        [0, E, 1, 1, 0],
        [0, 0, 0, 0, 0]
    ]), new Situation([
        [0, 0, 0, 0, 0, 0],
        [0, E, 1, 1, 1, 0],
        [0, 1, 1, 1, 1, 0],
        [0, 0, 0, 0, 0, 0]
    ]), new Situation([
        [0, 0, 0, 0, 0, 0, 0],
        [0, 1, 1, 1, 1, 1, 0],
        [0, 1, 1, 1, 1, W, 0],
        [0, 0, 0, 0, 0, 0, 0]
    ])], Infinity, [
        'robovac_move',
        'robovac_rotate',
        'robovac_repeat_until_clean',
        'robovac_repeat',
        'robovac_if',
        'robovac_if_else'
    ]),
    new Level([new Situation([
        [0, 0, 0, 0, 0, 0, 0],
        [0, E, 1, 1, 1, 1, 0],
        [0, 1, 1, 1, 1, 1, 0],
        [0, 0, 0, 0, 0, 0, 0]
    ]), new Situation([
        [0, 0, 0, 0, 0],
        [0, E, 1, 1, 0],
        [0, 1, 1, 1, 0],
        [0, 1, 1, 1, 0],
        [0, 1, 1, 1, 0],
        [0, 0, 0, 0, 0]
    ]), new Situation([
        [0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, E, 1, 1, 1, 1, 1, 1, 0],
        [0, 1, 1, 1, 1, 1, 1, 1, 0],
        [0, 1, 1, 1, 1, 1, 1, 1, 0],
        [0, 1, 1, 1, 1, 1, 1, 1, 0],
        [0, 1, 1, 1, 1, 1, 1, 1, 0],
        [0, 1, 1, 1, 1, 1, 1, 1, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0]
    ])], Infinity, [
        'robovac_move',
        'robovac_rotate',
        'robovac_repeat_until_clean',
        'robovac_repeat',
        'robovac_repeat_times',
        'robovac_if'
    ])
];

export default levels;
