<script>
import Robovac from "./main";
import Situation from "./Situation.vue";

export default {
  components: {
    situation: Situation
  },
  computed: {
    room() {
      return Robovac.room;
    },
    robot() {
      return Robovac.robot;
    },
    currentStatus() {
      return Robovac.currentSituationStatus;
    },
    situations() {
      return Robovac.currentLevel.situations;
    }
  },
  methods: {
    status(index) {
      if (index === Robovac.currentSituationNum) {
        return this.currentStatus;
      } else {
        return index < Robovac.currentSituationNum ? "complete" : "incomplete";
      }
    }
  }
}
</script>

<template>
  <div>
    <situation :room="room" :robot="robot" :status="currentStatus"></situation>
    <div v-if="situations.length > 1" class="situation-thumbs">
      <situation v-for="(situation, index) in situations"
                 :room="situation.room()" :robot="situation.robot()"
                 :status="status(index)" :thumb="true"></situation>
    </div>
  </div>
</template>

<style scoped>
.situation-thumbs {
  display: flex;
  gap: 5px;
}
</style>
