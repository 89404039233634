<script>
import Robovac from "./main";

export default {
  computed: {
    levels() {
      return [...Robovac.levels.keys()];
    }
  },
  methods: {
    isCurrent(level) {
      return level === Robovac.currentLevelNum;
    }
  }
}
</script>

<template>
  <ul>
    <li v-for="level in levels" :class="isCurrent(level) ? 'current' : ''">
      <a :href="'?level=' + level">{{ level }}</a>
    </li>
  </ul>
</template>

<style scoped>
  ul {
    margin: 0 0 0.5em 0;
    padding: 0;
    display: flex;
    gap: 0.5em;
    list-style: none;
  }
  a {
    display: block;
    box-sizing: content-box;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    padding: 0.5rem;
    border-radius: 3rem;
    font-size: 1.3em;
    background: #eee;
    text-align: center;
    color: black;
    text-decoration: none;
  }
  a:hover {
    background: #7f8fa9;
    color: white;
  }
  .current a {
    background: #297FD5;
    color: white;
  }
</style>
