<script>
import {CLEAN, DIRTY} from "./model";

export default {
  props: ["room", "robot", "status", "thumb"],
  computed: {
    roomWidth() {
      return this.room.squares[0].length;
    },
    roomHeight() {
      return this.room.squares.length;
    },
    maxSide() {
      return Math.max(this.roomWidth, this.roomHeight);
    },
    roomSquareList() {
      let list = [];
      for (let y = 0; y < this.roomHeight; y++) {
        for (let x = 0; x < this.roomWidth; x++) {
          if (this.room.squares[y][x] > 0) {
            list.push({
              x: x,
              y: y,
              classes: {
                clean: this.room.squares[y][x] === CLEAN,
                dirty: this.room.squares[y][x] === DIRTY,
                thumb: this.thumb
              }
            });
          }
        }
      }
      return list;
    },
    robotStyle() {
      return {
        "--x": this.robot.x + ".5px",
        "--y": this.robot.y + ".5px",
        "--angle": this.robot.angle() + "rad"
      };
    }
  }
}
</script>

<template>
  <svg viewBox="0 0 100 100">
    <g class="status" :class="status">
      <rect width="100" height="100" class="border"></rect>
    </g>
    <svg :viewBox="'0.5 0.5 ' + (maxSide - 1) + ' ' + (maxSide - 1)">
      <svg :viewBox="'0 0 ' + roomWidth + ' ' + roomHeight"
           :width="maxSide" :height="maxSide">
        <g>
          <rect v-for="square in roomSquareList" class="room-square"
                :x="square.x" :y="square.y" width="1" height="1"
                :class="square.classes">
          </rect>
        </g>
        <g class="robot" :style="robotStyle">
          <circle r="0.45"></circle>
          <circle r="0.07" cx="0.35" fill="white"></circle>
        </g>
      </svg>
    </svg>
  </svg>
</template>

<style scoped>
svg {
  background: #eee;
}

.status {
  fill: none;
  stroke: none;
  stroke-width: 5;
}

.status .border {
  fill: none;
  stroke: none;
  stroke-width: 5;
}

.status.complete .border {
  stroke: forestgreen;
}

.status.failed .border {
  stroke: darkred;
}

.room-square {
  stroke: #888;
  stroke-width: 0.01;
}

.room-square.dirty {
  fill: #ccc;
}

.room-square.clean {
  fill: #fff;
}

.room-square.thumb {
  stroke-width: 0.03;
}

.robot {
  transform: translate(var(--x), var(--y)) rotate(var(--angle));
  transition: transform 0.2s ease;
}
</style>
