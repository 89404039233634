import Blockly from "blockly";
import {createApp, reactive} from "vue";

import {CLEAN, DIRTY, OUTSIDE, Room, Robot} from "./model";
import levels from "./levels";
import Level from "./Level.vue";
import LevelSelector from "./LevelSelector.vue"

const Robovac = reactive({
    levels: levels,
    currentLevel: null,
    currentLevelNum: 0,
    currentSituation: null,
    currentSituationNum: 0,
    room: null,
    robot: null,
    currentSituationStatus: "incomplete",
    initialize: initialize,
    afterCode: afterCode
});
export default Robovac;
window.algolabRobovac = Robovac; // used in generated code

let workspace;

function initialize(theWorkspace) {
    createApp(LevelSelector).mount("#topArea");

    workspace = theWorkspace;
    workspace.addChangeListener(blocksChanged);

    document.getElementById("executeButton").onclick = function () {
        if (!window.algolabRunning) {
            execute();
        }
    };

    let levelParam = new URL(document.location).searchParams.get('level');
    let initialLevel = Number.parseInt(levelParam);
    if (0 <= initialLevel && initialLevel < levels.length) {
        loadLevel(initialLevel);
    } else {
        loadLevel(0);
    }

    window.onpopstate = function (event) {
        loadLevel(event.state.level);
    }

    createApp(Level).mount("#actionArea");
}

function execute() {
    let code = generateCode();
    console.log(code);
    window.algolabRunning = true;
    eval(code);
}

function loadLevel(levelNum) {
    Robovac.currentLevelNum = levelNum;
    Robovac.currentLevel = levels[levelNum];

    history.pushState({level: levelNum}, document.title, '?level=' + levelNum);
    let toolbox = {
        contents: Robovac.currentLevel.blockTypes.map(type => ({
            kind: 'block',
            type: type
        }))
    };
    workspace.updateToolbox(toolbox);
    workspace.options.maxBlocks = Robovac.currentLevel.maxBlocks;

    loadSituation(0);
}

function loadSituation(situationNum) {
    Robovac.currentSituationNum = situationNum;
    Robovac.currentSituation = Robovac.currentLevel.situations[situationNum];
    Robovac.room = Robovac.currentSituation.room();
    Robovac.robot = Robovac.currentSituation.robot();
    Robovac.currentSituationStatus = "incomplete";
}

function blocksChanged(event) {
    if (event.type === Blockly.Events.BLOCK_CREATE ||
            event.type === Blockly.Events.BLOCK_DELETE) {
        let countArea = document.getElementById('blockCountArea');
        if (workspace.options.maxBlocks === Infinity) {
            countArea.textContent = '';
        } else {
            countArea.textContent = workspace.remainingCapacity() + ' blocks remaining';
        }
    }
}

function generateCode() {
    let blocklyCode = Blockly.JavaScript.workspaceToCode(workspace);
    return '' +
        'const OUTSIDE = ' + OUTSIDE + ';\n' +
        'const DIRTY = ' + DIRTY + ';\n' +
        'const CLEAN = ' + CLEAN + ';\n' +
        'let robot = window.algolabRobovac.robot;\n' +
        'let room = window.algolabRobovac.room;\n' +
        'let sleep_time = 300;\n' +
        '(async () => {\n' +
        'try {\n' +
        '  ' + blocklyCode.replaceAll(/\n(?!$)/g, '\n  ') +
        '  if (room.isClean()) {\n' +
        '    window.algolabRobovac.currentSituationStatus = "complete";\n' +
        '  } else {\n' +
        '    window.algolabRobovac.currentSituationStatus = "failed";\n' +
        '  }\n' +
        '} catch(e) {\n' +
        '  if (e.name === "crash") {\n' +
        '    window.algolabRobovac.currentSituationStatus = "failed";\n' +
        '  } else if (e.name !== "stop") {\n' +
        '    console.log(e);\n' +
        '  }\n' +
        '}\n' +
        'setTimeout(window.algolabRobovac.afterCode, 1000);\n' +
        '})();';
}

function afterCode() {
    if (Robovac.currentSituationStatus === "complete" && Robovac.currentSituationNum + 1 < Robovac.currentLevel.situations.length) {
        loadSituation(Robovac.currentSituationNum + 1);
        setTimeout(execute, 500);
    } else {
        window.algolabRunning = false;
        let levelToLoad;
        if (Robovac.currentSituationStatus !== "complete") {
            levelToLoad = Robovac.currentLevelNum; // restart level
        } else if (Robovac.currentLevelNum < levels.length - 1) {
            levelToLoad = Robovac.currentLevelNum + 1; // level up!
            workspace.clear();
        } else {
            levelToLoad = 0; // restart game
            workspace.clear();
        }
        loadLevel(levelToLoad);
    }
}
